import { Row } from '@tanstack/react-table';

import { ColumnDef } from './types';

/** Calculates the left position for a sticky column based on its index and the width of preceding columns. */
export function getStickyColumnLeftPosition(index: number, columns: ColumnDef<any, any>[]): number {
  if (!index) return 0;
  const prevColumnsTotalWidth = columns
    .slice(0, index)
    .reduce((curr, column) => curr + Number(column.meta?.style?.width ?? 150), 0);
  return prevColumnsTotalWidth;
}

/** Computes the row span mapping for merged cells across all rows in a table.  */
export function getMergeRowSpans<TData, TValue>(rows: Row<TData>[], columns: ColumnDef<TData, TValue>[]) {
  // Will work on these merge enabled column keys
  const mergeKeys: string[] = columns.filter((c) => c.id && c.meta?.merge).map((c) => c.id!);
  const spans: Record<string, { first: number; last: number }[]> = {};

  // Generates a unique identifier for a row based on preceding merge keys.This ensures that merging only occurs within identical groupings.
  const getIdentifier = (idx: number, key: string) =>
    mergeKeys
      .slice(0, mergeKeys.indexOf(key))
      .map((k) => spans[k]?.[idx]?.toString() ?? '')
      .join('');

  // Calculates the list of pairs of spans across rows for each column => [{first:0,last:3},{first:4,last:17}...]
  mergeKeys.forEach((key) => {
    let count = 0;
    let val = rows[0].getValue(key);
    let idx = 0;
    spans[key] = [];
    let prev = getIdentifier(0, key);

    // Iterate through rows to determine span groups first scan to fill first values
    rows.forEach((row, rowIdx) => {
      const curr = getIdentifier(rowIdx, key);
      if (row.getValue(key) !== val || prev !== curr) {
        val = row.getValue(key);
        prev = curr;
        count += 1;
        idx = rowIdx;
      }
      spans[key].push({ first: idx, last: count });
    });

    // Second scan to fill last values for each pair
    let lastIdx = spans[key].length - 1;
    for (let rowIdx = lastIdx; rowIdx >= 0; rowIdx -= 1) {
      const span = spans[key][rowIdx];
      span.last = lastIdx;
      if (rowIdx === span.first) {
        lastIdx = rowIdx - 1;
      }
    }
  });

  return spans;
}
