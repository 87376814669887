import { useTheme } from '@src/client/ui-library/theme-provider';

import LucidIconWrapper from '../lucid-icon-wrapper';
import ExpandedNavigationMenu from './ExpandedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function ExperimentsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
  panelRef,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
  panelRef: React.RefObject<HTMLDivElement>;
}) {
  const isABExperimentOpen = currentPathname.includes('experiment/list');
  const isFeatureFlagOpen = currentPathname.includes('feature-flag/list');
  const isExperimentsOpen = isABExperimentOpen || isFeatureFlagOpen;
  const { theme } = useTheme();

  return (
    <ExpandedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Experiments"
      isActive={isExperimentsOpen}
      panelRef={panelRef}
      triggerIcon={
        <LucidIconWrapper name="test-tube-diagonal" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
      }
      toggleSidepanel={toggleSidepanel}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<LucidIconWrapper name="test-tube-diagonal" strokeWidth={1.5} />}
          description={isNavigationSidepanelOpen ? 'Compare variations, Control feature releases' : 'Experiments'}
        />
      }
    >
      <div className="flex flex-col gap-2 pt-1">
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="experiment/list"
          text="Experiment"
          panelRef={panelRef}
          icon={
            <LucidIconWrapper name="test-tube-diagonal" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
          }
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="test-tube-diagonal" strokeWidth={1.5} />}
              description="Test and compare product/feature variations to identify the best performer."
            />
          }
        />
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="feature-flag/list"
          text="Feature Flags"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="toggle-right" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="toggle-right" strokeWidth={1.5} />}
              description="Roll out new features to specific user groups for controlled testing."
            />
          }
        />
      </div>
    </ExpandedNavigationMenu>
  );
}
