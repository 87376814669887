import Layout from '@src/client/components/layout';
import { useCheckSdkIntegrationOnHomepage } from '@src/client/helpers/reports/hooks';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ScreenNames } from '@src/client/routes/data';
import { PageLoader } from '@src/client/ui-library/loaders';
import { Navigate } from 'react-router-dom';

import Home from './Home';

export default function HomeScreen() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const { isSdkIntegrated, isSdkLoading } = useCheckSdkIntegrationOnHomepage();

  if (isSdkLoading) return <PageLoader />;

  if (!isSdkIntegrated)
    return (
      <Navigate
        to={{
          pathname: getLinkWithWorkspace('sdk-integrations'),
        }}
      />
    );

  return (
    <Layout screenName={ScreenNames.HomeScreen}>
      <Home />
    </Layout>
  );
}
