import { userInfoState } from '@src/client/recoil/atoms';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useUserInfo = () => {
  const userInfo = useRecoilValue(userInfoState);
  const [userName, setUserName] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (userInfo) {
      setUserName(`${userInfo.firstName ?? ''} ${userInfo.lastName ?? ''}`.trim() || 'Guest');
      setIconUrl(userInfo.profileUrl ?? '');
      setEmail(userInfo.email ?? '');
    } else {
      setUserName('Guest');
      setIconUrl('');
    }
  }, [userInfo]);

  return { userName, iconUrl, email };
};
