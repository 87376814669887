import { atom } from 'recoil';

export const tableHoverRowState = atom<string | undefined>({
  key: 'tableHoverRowState',
  default: undefined,
});

export const tableHoverMultiRowState = atom<string[]>({
  key: 'tableHoverMultiRowState',
  default: [],
});
