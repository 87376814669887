import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { cn } from '@src/client/ui-library/utils';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import LinkInfoTooltip from './LinkInfoTooltip';

export default function SidepanelNavLink({
  isNavigationSidepanelOpen,
  path,
  text,
  icon,
  rounded = true,
  reloadPage = false,
  tooltipContent,
  rightNode,
  panelRef,
}: {
  isNavigationSidepanelOpen: boolean;
  path: string;
  text: string;
  icon: React.ReactNode;
  rounded?: boolean;
  reloadPage?: boolean;
  tooltipContent?: React.ReactNode;
  rightNode?: React.ReactNode;
  panelRef?: React.RefObject<HTMLDivElement>;
}) {
  const { pathname: currentPathname } = useLocation();
  const isActivePath = currentPathname.includes(path);
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const classNamesToApply = cn(
    SidepanelNavLinkClassNames,
    isActivePath ? SidepanelActiveNavClassNames : '',
    isNavigationSidepanelOpen ? '' : 'justify-center',
    rounded ? 'rounded-md' : 'rounded-none',
  );
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (panelRef && isNavigationSidepanelOpen && isActivePath && linkRef.current && panelRef.current) {
      requestAnimationFrame(() => {
        linkRef.current?.scrollIntoView({ behavior: 'auto', block: 'center' });
      });
    }
  }, [isActivePath, panelRef, isNavigationSidepanelOpen]);

  return (
    <LinkInfoTooltip content={tooltipContent ?? null} side="right" sideOffset={12}>
      <Link
        ref={linkRef}
        to={getLinkWithWorkspace(path)}
        className={classNamesToApply}
        reloadDocument={reloadPage}
        data-testid={`${path.replaceAll('/', '-')}-nav-link`}
      >
        {isNavigationSidepanelOpen ? (
          <div className="flex w-full items-center justify-between">
            <div className="flex space-x-2 items-center">
              {icon}
              <p>{text}</p>
            </div>
            {rightNode || null}
          </div>
        ) : (
          icon
        )}
      </Link>
    </LinkInfoTooltip>
  );
}
