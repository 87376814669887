import { useLogout } from '@src/client/hooks';
import { Avatar, AvatarFallback, AvatarImage } from '@src/client/ui-library/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { useTheme } from '@src/client/ui-library/theme-provider';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { useUserInfo } from './hooks';

export default function UserMenu() {
  const { userName, iconUrl, email } = useUserInfo();
  const { handleLogout } = useLogout();
  const { theme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="p-2 mr-3 flex gap-1 items-center rounded-md  dark:bg-transparent text-white hover:text-white dark:hover:bg-background hover:bg-neutral  data-[active]:text-white data-[active]:bg-neutral data-[state=open]:bg-neutral dark:data-[active]:bg-gray-975 dark:data-[state=open]:bg-gray-975  data-[state=open]:text-white"
        aria-label="User menu"
      >
        <Avatar className="-my-2 w-7 h-7">
          <AvatarImage src={iconUrl} />
          <AvatarFallback className="bg-[#8E62FE] text-white">{userName?.[0] ?? 'U'}</AvatarFallback>
        </Avatar>

        <div className="text-sm font-semibold mx-2">{userName}</div>

        <LucidIconWrapper name="chevron-down" strokeWidth={1.5} stroke="white" />
      </DropdownMenuTrigger>

      <DropdownMenuContent className=" w-auto min-w-56 mt-3 p-2 " side="bottom" align="end">
        <div className="w-fit">
          <p className="text-sm text-foreground-selected p-2">{email}</p>
          <DropdownMenuSeparator />
          <DropdownMenuLabel
            onClick={handleLogout}
            className="flex items-center justify-between p-2 cursor-pointer rounded-md bg-transparent hover:bg-foreground-secondary dark:hover:bg-gray-975 dark:text-white"
          >
            <p className="text-sm font-medium text-foreground-selected">Logout</p>
            <LucidIconWrapper name="log-out" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
          </DropdownMenuLabel>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
