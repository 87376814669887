import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon, MinusIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { cn } from '../utils';

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  checked,
  onChange,
}: {
  checked: boolean;
  indeterminate: boolean;
  className?: string;
  onChange: (event: unknown) => void;
}) {
  return (
    <CheckboxPrimitive.Root
      onClick={(e) => onChange(e)}
      className={cn(
        'h-4 w-4 shrink-0 rounded border border-border',
        checked || indeterminate ? 'bg-primary' : 'bg-white',
        className,
      )}
    >
      {indeterminate && <MinusIcon color="white" />}
      {checked && <CheckIcon color="white" />}
    </CheckboxPrimitive.Root>
  );
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & { indeterminate?: boolean }
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-4 w-4 shrink-0 bg-white rounded border border-border focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center')}>
      <CheckIcon className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
