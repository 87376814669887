import Lottie from 'lottie-react';

import { PerceptIconSmall } from '../icons/v3';
import barAnimation from '../lottie-files/bar-loader.json';
import sdkIntegrationLoader from '../lottie-files/sdk-integration-check.json';
import sdkIntegrationComplete from '../lottie-files/sdk-integration-complete.json';
import spinAnimation from '../lottie-files/spin-loader.json';
import { useUIVersion } from '../theme-provider/uiProvider';
import { cn } from '../utils';

export function AnimatedPILoader({ relative }: { relative?: boolean }) {
  return (
    <div className={relative ? '' : 'absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'}>
      {/* <img src="/images/pi_logo_color.svg" alt="no-sdk-integrated-icon" className="h-8 w-8 animate-flickerAnimation" /> */}
      <div className="h-8 w-8 animate-flickerAnimation">
        <PerceptIconSmall />
      </div>
    </div>
  );
}

export function SpinLoader({
  size = 'regular',
  relative = false,
  noPiLoader = false,
}: {
  size?: 'small' | 'regular';
  relative?: boolean;
  noPiLoader?: boolean;
}) {
  const { uiVersion } = useUIVersion();

  // if (uiVersion === 'v3' && !noPiLoader) {
  //   return <AnimatedPILoader relative={relative} />;
  // }

  return (
    <Lottie
      animationData={spinAnimation}
      loop
      className={`${size === 'regular' ? 'h-12' : 'h-8'} ${
        relative ? '' : 'absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'
      }`}
    />
  );
}

export function BarLoader({ relative = false }: { relative?: boolean }) {
  const { uiVersion } = useUIVersion();

  if (uiVersion === 'v3') {
    return <AnimatedPILoader relative={relative} />;
  }

  return (
    <Lottie
      animationData={barAnimation}
      loop
      className="h-24 absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4"
    />
  );
}

export function PageLoader() {
  const { uiVersion } = useUIVersion();

  if (uiVersion === 'v3') {
    return (
      <div className="flex h-screen bg-background">
        <div className="w-50 h-screen bg-border" />
        <div className="bg-background text-foreground h-screen box-border pt-[60px]">
          <AnimatedPILoader />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-background">
      <div className="h-navbar bg-neutral w-full" />
      <div className="pt-[60px] bg-background text-foreground min-h-layout box-border">
        <AnimatedPILoader />
      </div>
    </div>
  );
}

export function AnimatedRadarPing() {
  return (
    <div className="relative flex h-3 w-3">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-dark dark:bg-primary-light opacity-75" />
      <span className="relative inline-flex rounded-full h-3 w-3 bg-primary" />
    </div>
  );
}

export function SdkIntegrationCheckLoader({ relative = true, className }: { relative?: boolean; className?: string }) {
  return (
    <Lottie
      animationData={sdkIntegrationLoader}
      loop
      className={cn(
        relative ? 'h-60' : 'h-60 absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4',
        className,
      )}
    />
  );
}

export function SdkIntegrationCheckComplete({ className }: { className?: string }) {
  return <Lottie animationData={sdkIntegrationComplete} className={cn(className)} />;
}
