import Layout from '@src/client/components/layout';
import { ScreenNames } from '@src/client/routes/data';

import HomeRightPanel from './components/HomeRightPanel';
import ReportsView from './components/ReportsView';

export default function AllReportsScreen() {
  return (
    <Layout screenName={ScreenNames.HomeScreen}>
      <div className="flex relative w-full">
        <div className="flex-1 py-6 px-4">
          <ReportsView currentUserOnly={false} />
        </div>
        <HomeRightPanel hideCreatedBy={false} />
      </div>
    </Layout>
  );
}
