import { useIsWorkspaceCreationEnabled, useWorkspaceSelector } from '@src/client/hooks';
import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import { Avatar, AvatarFallback } from '@src/client/ui-library/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { PlusIcon } from '@src/client/ui-library/icons/HomepageIcons';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { DEMO_WORKSPACE_ID } from './constants';
import { sortWorkspaces } from './utils';
import { workspaceIcons } from './WorkspaceIcons';

export default function WorkspaceSelector({ isOutline = false }: { isOutline?: boolean }) {
  const userInfo = useRecoilValue(userInfoState);
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  const handleWorkspaceSelection = useWorkspaceSelector();
  const navigate = useNavigate();
  const isWorkspaceCreationEnabled = useIsWorkspaceCreationEnabled();
  const { theme } = useTheme();

  if (!userInfo?.workspaces || !userInfo?.activeWorkspaceId) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="p-2 mr-3 flex gap-1 items-center rounded-md  dark:bg-transparent text-white hover:text-white dark:hover:bg-background hover:bg-neutral  data-[active]:text-white data-[active]:bg-neutral data-[state=open]:bg-neutral dark:data-[active]:bg-background dark:data-[state=open]:bg-background  data-[state=open]:text-white">
        {activeWorkspace?.logoUrl &&
        activeWorkspace?.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
          <img src={activeWorkspace.logoUrl} className="h-6 w-6" alt={`${activeWorkspace?.name} logo`} />
        ) : (
          <Avatar className="-my-2 w-7 h-7">
            <AvatarFallback className="bg-[#FE7062] text-white">{activeWorkspace?.name[0] ?? 'U'}</AvatarFallback>
          </Avatar>
        )}
        <div className="text-sm font-semibold mx-1">{activeWorkspace?.name}</div>
        <LucidIconWrapper name="chevron-down" strokeWidth={1.5} stroke="white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-50 max-h-[600px] p-2 mt-3 font-medium overflow-y-scroll "
        side="bottom"
        align="end"
      >
        {sortWorkspaces(userInfo?.workspaces ?? [])
          .filter((w) => w.id !== DEMO_WORKSPACE_ID)
          .map((workspace, index) => (
            <DropdownMenuItem
              key={workspace.id}
              onClick={() => handleWorkspaceSelection(workspace)}
              className={`cursor-pointer rounded-md m-1 dark:hover:bg-gray-975 dark:text-white ${
                workspace.id === activeWorkspace?.id ? 'bg-foreground-secondary dark:bg-gray-975' : ''
              }`}
            >
              <div className="mx-2 inline-flex items-center space-x-2">
                {workspace.logoUrl && workspace.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
                  <img src={workspace.logoUrl} className="h-6 w-6" alt={`${workspace?.name} logo`} />
                ) : (
                  workspaceIcons[index % 3]
                )}
                <p>{workspace.name}</p>
              </div>
            </DropdownMenuItem>
          ))}
        {isWorkspaceCreationEnabled && (
          <DropdownMenuItem
            key="create-workspace"
            onClick={() => navigate('/create-workspace')}
            className="cursor-pointer rounded-md dark:hover:bg-gray-975 dark:text-white"
          >
            <div className="mx-2 inline-flex items-center space-x-2">
              <PlusIcon stroke={theme === 'dark' ? 'white' : ''} />
              <p>Create Workspace</p>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
