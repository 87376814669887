import { ReportFilterOptionType } from '@src/client/components/filters-and-selectors/report-type-filter';
import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import { PlatformItemType } from '@src/client/lib/api/types/request';
import { ReportType } from '@src/client/lib/api/types/response';
import { FlowIcon, FunnelIcon, RetentionIcon } from '@src/client/ui-library/icons/v3';

export const ReportImages: Record<ReportType, string> = {
  [ReportType.INSIGHT]: '/images/v2/insights-small.png',
  [ReportType.FUNNEL]: '/images/v2/funnels-small.png',
  [ReportType.FLOW]: '/images/v2/flows-small.png',
  [ReportType.RETENTION]: '/images/v2/retention-small.png',
};

export const reportTypeIcons: Record<ReportFilterOptionType['value'], React.ReactNode> = {
  [ReportType.INSIGHT]: <LucidIconWrapper name="chart-bar-big" strokeWidth={1.5} stroke="white" />,
  [ReportType.FUNNEL]: <FunnelIcon stroke="white" />,
  [ReportType.FLOW]: <FlowIcon stroke="white" />,
  [ReportType.RETENTION]: <RetentionIcon stroke="white" />,
  [PlatformItemType.COHORT]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.REPORT]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.DASHBOARD]: <LucidIconWrapper name="layout-dashboard" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.EVENT]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.CUSTOM_EVENT]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.CAMPAIGN]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
  [PlatformItemType.ALERT]: <LucidIconWrapper name="dot" strokeWidth={1.5} stroke="white" />,
};

export const reportTypePathMap: Record<ReportFilterOptionType['value'], string> = {
  [ReportType.INSIGHT]: 'insights',
  [ReportType.FUNNEL]: 'funnels',
  [ReportType.FLOW]: 'flows',
  [ReportType.RETENTION]: 'retention',
  [PlatformItemType.COHORT]: '',
  [PlatformItemType.REPORT]: '',
  [PlatformItemType.DASHBOARD]: 'dashboard',
  [PlatformItemType.EVENT]: '',
  [PlatformItemType.CUSTOM_EVENT]: '',
  [PlatformItemType.CAMPAIGN]: '',
  [PlatformItemType.ALERT]: '',
};
