/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@src/client/ui-library/theme-provider';
import { cn } from '@src/client/ui-library/utils';
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import LinkInfoTooltip from './LinkInfoTooltip';

interface Props extends PropsWithChildren {
  isNavigationSidepanelOpen: boolean;
  triggerText: string;
  triggerIcon: ReactNode;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  tooltipContent?: React.ReactNode;
  isExpanded?: boolean;
  panelRef: React.RefObject<HTMLDivElement>;
}

export default function ExpandedNavigationMenu({
  children,
  triggerText,
  triggerIcon,
  toggleSidepanel,
  isActive,
  tooltipContent,
  isNavigationSidepanelOpen,
  isExpanded = false,
  panelRef,
}: Props) {
  const { theme } = useTheme();
  const storageKey = `nested-nav-${triggerText.replace(/\s+/g, '-')}-state`;
  const [isOpen, setIsOpen] = useState(() => JSON.parse(localStorage.getItem(storageKey) || String(isExpanded)));
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [shouldCloseTemporarily, setShouldCloseTemporarily] = useState(false);

  useEffect(() => {
    if (!shouldCloseTemporarily) {
      localStorage.setItem(storageKey, JSON.stringify(isOpen));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isNavigationSidepanelOpen) {
      setShouldCloseTemporarily(true);
    } else {
      setShouldCloseTemporarily(false);
      setIsOpen(JSON.parse(localStorage.getItem(storageKey) || 'false'));
    }
  }, [isNavigationSidepanelOpen]);

  return (
    <div className="flex flex-col w-full">
      <button
        type="button"
        data-testid={`${triggerText.replaceAll(' ', '-')}-nav-button`}
        onClick={() => {
          setIsOpen((prev: boolean) => !prev);
          if (!isNavigationSidepanelOpen) {
            toggleSidepanel(true);
          }
          requestAnimationFrame(() => {
            if (dropdownRef.current && panelRef.current && !isOpen) {
              const dropdown = dropdownRef.current;
              const panel = panelRef.current;

              const dropdownOffsetTop = dropdown.offsetTop;
              const dropdownHeight = dropdown.offsetHeight;
              const panelHeight = panel.clientHeight;

              const scrollTop = dropdownOffsetTop - panelHeight / 2 + dropdownHeight / 2;

              panel.scrollTo({
                top: scrollTop,
                behavior: 'smooth',
              });
            }
          });
        }}
        className={cn(
          SidepanelNavLinkClassNames,
          isOpen ? '' : isActive ? SidepanelActiveNavClassNames : '',
          isNavigationSidepanelOpen ? '' : isActive ? SidepanelActiveNavClassNames : '',
        )}
      >
        <LinkInfoTooltip content={tooltipContent ?? null} side="right" sideOffset={12}>
          <div className="w-full flex justify-between">
            {isNavigationSidepanelOpen ? (
              <>
                <span className="text-sm font-bold text-gray-550 pl-1 dark:text-gray-150">{triggerText}</span>
                <LucidIconWrapper
                  name={isOpen ? 'chevron-up' : 'chevron-down'}
                  strokeWidth={1.5}
                  stroke={theme === 'dark' ? 'white' : ''}
                />
              </>
            ) : (
              <span className="flex justify-center">{triggerIcon}</span>
            )}
          </div>
        </LinkInfoTooltip>
      </button>

      <div
        ref={dropdownRef}
        className={cn(
          'overflow-hidden transition-all duration-300 ease-in-out',
          shouldCloseTemporarily || !isOpen ? 'h-0 opacity-0 pointer-events-none' : 'h-auto opacity-100',
        )}
      >
        <div className="w-full flex flex-col">{children}</div>
      </div>
    </div>
  );
}
