import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useIsExperimentEnabled, useIsFeatureFlagEnabled, useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@src/client/ui-library/dropdown';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { cn } from '@src/client/ui-library/utils';
import { ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import CreateMenuEngage from './CreateMenuEngage';
import CreateMenuExperiments from './CreateMenuExperiments';
import CreateMenuReports from './CreateMenuReports';

function MenuItem({
  iconName,
  label,
  isActive,
  onClick,
  navigateTo,
  theme,
}: {
  iconName: ValidIconName;
  label: string;
  isActive: boolean;
  onClick?: () => void;
  navigateTo?: string;
  theme: 'light' | 'dark';
}) {
  const navigate = useNavigate();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  const handleClick = () => {
    if (navigateTo) {
      navigate(getLinkWithWorkspace(navigateTo));
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={cn(
        SidepanelNavLinkClassNames,
        isActive ? SidepanelActiveNavClassNames : '',
        'w-[144px] text-left py-2 pl-2',
      )}
      onClick={handleClick}
    >
      <LucidIconWrapper name={iconName} strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
      <span>{label}</span>
      {isActive && label !== 'Dashboards' && (
        <LucidIconWrapper name="play" fill="currentColor" strokeWidth={1.5} stroke="black" size={10} />
      )}
    </button>
  );
}

const getActiveMenu = (currentPathname: string, toggleSidepanel: (value: boolean) => void) => {
  if (
    (currentPathname.includes('insights') && !currentPathname.endsWith('insights-list')) ||
    (currentPathname.includes('funnels') && !currentPathname.endsWith('funnels-list')) ||
    (currentPathname.includes('flows') && !currentPathname.endsWith('flows-list')) ||
    (currentPathname.includes('retention') &&
      !currentPathname.includes('insights-hub') &&
      !currentPathname.endsWith('retentions-list'))
  ) {
    toggleSidepanel(false);
    return 'reports';
  }
  if (
    currentPathname.includes('flexicast/create') ||
    currentPathname.endsWith('engage/create') ||
    currentPathname.includes('engage/create-template')
  ) {
    return 'engage';
  }
  if (currentPathname.includes('experiment/create') || currentPathname.includes('feature-flag/create')) {
    return 'experiments';
  }
  if (currentPathname.endsWith('dashboard')) {
    return 'dashboards';
  }
  return 'reports';
};

export default function CreateMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [activeMenu, setActiveMenu] = useState<'reports' | 'engage' | 'experiments' | 'dashboards'>('reports');
  const { theme } = useTheme();
  const isExperimentEnabled = useIsExperimentEnabled();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();
  const canShowExperiments = isExperimentEnabled || isFeatureFlagEnabled || isWorkspaceCreatedViaOnboardingFlow;

  useEffect(() => {
    setActiveMenu(getActiveMenu(currentPathname, toggleSidepanel));
  }, [currentPathname, toggleSidepanel]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          'py-2 h-10 w-full flex justify-between items-center font-normal text-md rounded-md bg-blue-600 text-white hover:bg-blue-700 data-[state=open]:bg-blue-600 data-[state=open]:text-white  dark:data-[state=open]:text-white dark:data-[state=open]:bg-blue-600',
          isNavigationSidepanelOpen ? 'px-4' : 'px-2',
        )}
      >
        {isNavigationSidepanelOpen ? (
          <>
            <span className="mx-1">Create</span>
            <ChevronRight strokeWidth={1.5} />
          </>
        ) : (
          <span className="w-full flex justify-center">
            <LucidIconWrapper name="plus" strokeWidth={1.5} stroke="white" />
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        className={`absolute top-full left-full ${isNavigationSidepanelOpen ? 'ml-10' : 'ml-5'} -mt-5 w-[370px] h-[305px] overflow-y-auto bg-background shadow-lg rounded-lg p-4 border border-border overflow-clip`}
      >
        <div className="mt-2 h-full w-full grid grid-cols-[1fr_2px_1fr]">
          <div>
            <ul className="space-y-2 mt-2 pl-3">
              <li>
                <MenuItem
                  iconName="file-chart-column"
                  label="Report"
                  isActive={activeMenu === 'reports'}
                  onClick={() => setActiveMenu('reports')}
                  theme={theme as 'light' | 'dark'}
                />
              </li>
              <li>
                <MenuItem
                  iconName="megaphone"
                  label="Engage"
                  isActive={activeMenu === 'engage'}
                  onClick={() => setActiveMenu('engage')}
                  theme={theme as 'light' | 'dark'}
                />
              </li>
              <li>
                {canShowExperiments ? (
                  <MenuItem
                    iconName="test-tube-diagonal"
                    label="Experiment"
                    isActive={activeMenu === 'experiments'}
                    onClick={() => setActiveMenu('experiments')}
                    theme={theme as 'light' | 'dark'}
                  />
                ) : null}
              </li>
              <li>
                <MenuItem
                  iconName="layout-dashboard"
                  label="Dashboards"
                  isActive={activeMenu === 'dashboards'}
                  navigateTo="dashboard"
                  theme={theme as 'light' | 'dark'}
                />
              </li>
            </ul>
          </div>
          <div className="bg-foreground-secondary" style={{ width: '2px', height: '95%' }} />
          <div className="pl-1">
            {activeMenu === 'reports' && <CreateMenuReports currentPathname={currentPathname} />}
            {activeMenu === 'engage' && <CreateMenuEngage isNavigationSidepanelOpen={isNavigationSidepanelOpen} />}
            {activeMenu === 'experiments' && <CreateMenuExperiments />}
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
