/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useIsScopeAvailable } from '@src/client/helpers/access/hooks';
import { AccessScopes } from '@src/client/helpers/access/types';
import { useIsDemoWorkspace, useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { isPISdkIntegratedState } from '@src/client/modules/sdk-integration/atoms';
import { PerceptIconSmall, PerceptIconText } from '@src/client/ui-library/icons/v3';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import NavbarSearch from './NavbarSearch';
import SettingsMenu from './SettingsMenu';
import UserMenu from './UserMenu';
import WalletMenu from './WalletMenu';
import WorkspaceSelector from './WorkspaceSelector';

function PerceptLogo() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const strokeColor = '#FFFFFF';

  return (
    <Link to={getLinkWithWorkspace('home')} className="ml-3 flex items-center justify-between">
      <PerceptIconSmall stroke={strokeColor} />
      <PerceptIconText stroke={strokeColor} />
    </Link>
  );
}

function NavDisabledOverlay() {
  const isSdkIntegrated = useRecoilValue(isPISdkIntegratedState);
  const { toast } = useToast();

  const handleNavClicks = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    toast({ variant: 'warning', title: 'Nav menu is disabled', description: 'Please integrate SDK first' });
  };

  if (!isSdkIntegrated) {
    return (
      <div
        className="absolute left-0 right-0 top-0 bottom-0 z-10 cursor-not-allowed"
        role="presentation"
        onKeyDown={handleNavClicks}
        onClick={handleNavClicks}
      />
    );
  }

  return null;
}
export default function SiteNavbar() {
  const isScopeAvailable = useIsScopeAvailable();
  const isDemoWorkspace = useIsDemoWorkspace();

  return (
    <header
      className={`flex fixed top-0 left-0 right-0 border-0 border-neutral-dark dark:border-border h-navbar 
      bg-neutral-dark dark:bg-gray-975 px-4 z-[1000]`}
    >
      <div className="flex items-center justify-between w-full ">
        <div className="flex items-center justify-start relative">
          <PerceptLogo />
          <div className="w-[500px] ml-14 rounded-md bg-neutral text-white !hover:bg-neutral dark:bg-background">
            <NavbarSearch />
          </div>
        </div>
        <div className="flex items-center justify-start">
          {isScopeAvailable(AccessScopes.WALLET_READ) && !isDemoWorkspace && <WalletMenu />}
          <SettingsMenu />
          <WorkspaceSelector />
          <UserMenu />
        </div>
      </div>
    </header>
  );
}
