import { useIsDemoWorkspace, useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { SettingsIcon } from '../../ui-library/icons/NavbarIcons';
import LucidIconWrapper from '../lucid-icon-wrapper';
import SidepanelNavLink from '../navigation-sidepanel/SidepanelNavLink';
import { ElevatedAccessItems, SETTINGS_SUB_PAGES } from './constants';
import ThemeToggler from './ThemeToggler';

function DropdownItem({
  label,
  link,
  eventName,
  icon,
}: {
  label: string;
  link: string;
  eventName: EventNames;
  icon: ReactNode;
}) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  return (
    <div>
      <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
        <Link
          to={getLinkWithWorkspace(link)}
          onClick={() => Tracker.trackEvent(eventName)}
          className="inline-flex items-center"
        >
          <div className="px-2 w-8">{icon}</div>
          <div className="ml-4 text-sm font-semibold">{label}</div>
        </Link>
      </DropdownMenuLabel>
      <DropdownMenuSeparator className="px-2 my-2" />
    </div>
  );
}

export default function SettingsMenu({ restricted = false }: { restricted?: boolean }) {
  const isDemoWorkspace = useIsDemoWorkspace();
  const { theme } = useTheme();
  const isSettingsSubPageOpen = SETTINGS_SUB_PAGES.some((p) => window.location.pathname.includes(p));

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={`bg-transparent mx-2 rounded-md ${isSettingsSubPageOpen ? 'bg-neutral dark:bg-background' : ''} dark:hover:bg-background hover:bg-neutral data-[active]:bg-neutral dark:data-[active]:bg-background dark:data-[state=open]:bg-background data-[state=open]:bg-neutral`}
      >
        <SettingsIcon width={28} height={28} strokeWidth={1.5} stroke="white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 p-2 mr-50 mt-2.5" side="bottom" align="end">
        {!isDemoWorkspace && (
          <div className="flex flex-col gap-1">
            <SidepanelNavLink
              isNavigationSidepanelOpen
              path="alert/list"
              text="Alerts"
              icon={<LucidIconWrapper name="bell-ring" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen
              path="sdk-integrations"
              text="Sdk Integrations"
              icon={<LucidIconWrapper name="package-open" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
            />
          </div>
        )}
        <div className="my-1">
          <SidepanelNavLink
            isNavigationSidepanelOpen
            path="custom-events/list"
            text="Custom Events"
            icon={
              <LucidIconWrapper name="mouse-pointer-click" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
            }
          />
        </div>

        {!restricted && (
          <section className="flex flex-col gap-1">
            {ElevatedAccessItems.map(({ label, link, icon }) => (
              <div key={link}>
                <SidepanelNavLink
                  isNavigationSidepanelOpen
                  key={link}
                  path={link}
                  text={label}
                  icon={icon(theme as 'light' | 'dark')}
                />
              </div>
            ))}
          </section>
        )}
        <DropdownMenuLabel className="flex items-center justify-between mb-2 p-2">
          <div className="flex items-center gap-2">
            <LucidIconWrapper name="moon" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
            <p className="text-sm font-medium text-foreground-selected">Dark Mode</p>
          </div>
          <ThemeToggler />
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
