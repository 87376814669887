import { ReportTypes } from '@src/client/helpers/constants';
import { ReportType } from '@src/client/lib/api/types/response';
import { FlowIcon, FunnelIcon, InsightIcon, RetentionIcon } from '@src/client/ui-library/icons/v3';
import { useMemo } from 'react';

import SidepanelNavLink from './SidepanelNavLink';

export default function CreateMenuReports({ currentPathname }: { currentPathname: string }) {
  const ReportTypeDisplyInfo = useMemo(
    () => ({
      [ReportType.INSIGHT]: {
        title: 'Insight',
        path: 'insights',
        imageSrc: '/images/v2/insights-small.png',
        color: '#FFFAEB',
        icon: <InsightIcon />,
      },
      [ReportType.FUNNEL]: {
        title: 'Funnel',
        path: 'funnels',
        imageSrc: '/images/v2/funnels-small.png',
        color: '#FFFBFA',
        icon: <FunnelIcon />,
      },
      [ReportType.FLOW]: {
        title: 'Flows',
        path: 'flows',
        imageSrc: '/images/v2/flows-small.png',
        color: '#F6FEF9',
        icon: <FlowIcon />,
      },
      [ReportType.RETENTION]: {
        title: 'Retention',
        path: 'retention',
        imageSrc: '/images/v2/retention-small.png',
        color: '#ECFDFF',
        icon: <RetentionIcon />,
      },
    }),
    [],
  );

  return (
    <>
      {ReportTypes.map((reportType) => {
        const reportDisplayData = ReportTypeDisplyInfo[reportType];
        return (
          <div key={reportDisplayData.title + reportDisplayData.path} className="w-full pl-2 pr-4 py-2">
            <SidepanelNavLink
              isNavigationSidepanelOpen
              path={reportDisplayData.path}
              text={reportDisplayData.title}
              icon={<img height={32} width={32} src={reportDisplayData.imageSrc} alt={`${reportType} icon`} />}
            />
          </div>
        );
      })}
    </>
  );
}
