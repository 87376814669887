/* eslint-disable react/require-default-props */
import SiteNavbar from '@src/client/components/site-navbar';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ScreenNames } from '@src/client/routes/data';
import { ThemeProvider } from '@src/client/ui-library/theme-provider';
import { ThemeStorageKey } from '@src/client/ui-library/theme-provider/constants';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Toaster } from '../../ui-library/toast/toaster';
import NavigationSidepanel from '../navigation-sidepanel';

interface LayoutProps extends PropsWithChildren {
  screenName: ScreenNames;
  hideSidePanel?: boolean;
  contentStyle?: React.CSSProperties;
}

export default function Layout({ children, screenName, contentStyle = {}, hideSidePanel = false }: LayoutProps) {
  const location = useLocation();

  useEffect(() => {
    Tracker.setCurrentScreenName(screenName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location && location.pathname !== '/') {
      Tracker.trackEvent(
        EventNames.PAGE_VIEW,
        {},
        {
          pathName: location.pathname,
        },
      );
    }
  }, [location]);

  return (
    <ThemeProvider defaultTheme="light" storageKey={ThemeStorageKey}>
      <SiteNavbar />
      <main
        className={`bg-background text-foreground min-h-layout pt-[60px] flex box-content ${hideSidePanel ? 'pt-24' : ''}`}
        style={{ ...contentStyle }}
      >
        {!hideSidePanel ? <NavigationSidepanel /> : null}
        <div className="flex-grow w-full">{children}</div>
      </main>
      <Toaster />
    </ThemeProvider>
  );
}
