/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@src/client/hint.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { Suspense, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { version } from '../../package.json';
import AppErrorBoundaryFallback from './components/error-handling/AppErrorBoundaryFallback';
import GlobalAlert from './components/global-alert';
import GlobalPageLoader from './components/global-page-loader';
import Tracker from './lib/analytics/tracker';
import { generateMsalInstance } from './lib/msal-utils';
import Router from './routes';
import { PageLoader } from './ui-library/loaders';
import { UIStoragKey } from './ui-library/theme-provider/constants';
import { UIVersionProvider } from './ui-library/theme-provider/uiProvider';

const queryClient = new QueryClient();

function App() {
  const { VITE_REACT_APP_GOOGLE_CLIENT_ID } = import.meta.env;
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
  async function initializeMsal() {
    const instance = generateMsalInstance();
    await instance.initialize();
    setMsalInstance(instance);
  }

  useEffect(() => {
    console.log(`[PI] dashboard version: ${version}`);
    Tracker.init();
    initializeMsal();
  }, []);

  if (!msalInstance) {
    return <PageLoader />;
  }

  return (
    <React.StrictMode>
      <RecoilRoot>
        {/* {VITE_UDAAN_ENV === 'local' || VITE_UDAAN_ENV === 'dev' ? <RecoilDebugObserver /> : null} */}
        <CookiesProvider>
          <UIVersionProvider defaultUI="v3" storageKey={UIStoragKey}>
            <Suspense fallback={<PageLoader />}>
              <ErrorBoundary FallbackComponent={AppErrorBoundaryFallback}>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                  <GoogleOAuthProvider clientId={`${VITE_REACT_APP_GOOGLE_CLIENT_ID}`}>
                    <MsalProvider instance={msalInstance}>
                      <BrowserRouter>
                        <Router />
                        <GlobalPageLoader />
                        <GlobalAlert />
                      </BrowserRouter>
                    </MsalProvider>
                  </GoogleOAuthProvider>
                </QueryClientProvider>
              </ErrorBoundary>
            </Suspense>
          </UIVersionProvider>
        </CookiesProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
}

export default App;
