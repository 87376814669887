import { useUserInfoQuery } from '@src/client/hooks';
import { Cookie } from '@src/client/lib/api/constants';
import { AnimatedPILoader } from '@src/client/ui-library/loaders';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

export default function ActiveWorkspaceNavigator() {
  const [cookies] = useCookies([Cookie.id_token]);

  const {
    isLoading: isUserInfoLoading,
    userInfo,
    error,
  } = useUserInfoQuery({
    logoutOnError: true,
  });

  // eslint-disable-next-line no-underscore-dangle
  if (!cookies._id_token) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (error) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (isUserInfoLoading || !userInfo) {
    return (
      <div className="flex items-center justify-center h-layout bg-background">
        <AnimatedPILoader />
      </div>
    );
  }

  const wsToUse = userInfo.activeWorkspaceId ?? userInfo.workspaces[0].id;

  return (
    <Navigate
      to={{
        pathname: `/${wsToUse}/home`,
      }}
    />
  );
}
