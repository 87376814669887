import { EventNames } from '@src/client/lib/analytics/events';

import LucidIconWrapper from '../lucid-icon-wrapper';

export const ENGAGE_ALLOWED_WORKSPACES = ['wondermart', 'udaan-prod', 'sorted'];
export const ELEVATED_ACCESS_ROLES = ['Admin', 'Owner'];
export const USER_PROFILE_NOT_ALLOWED_TENANT = ['udaan'];
export const MARKETING_ANALYST = 'Marketing Analyst';
export const DEMO_WORKSPACE_ID = 'demo-ecommerce-app';

export const SETTINGS_SUB_PAGES = [
  'settings/manage-users',
  'settings/data-governance',
  'engage-settings',
  'alert/list',
  'sdk-integrations',
  'workspace-settings',
];
export const ElevatedAccessItems = [
  {
    label: 'Users and Teams',
    link: 'settings/manage-users',
    eventName: EventNames.MANAGE_USERS_CLICKED,
    icon: (theme: 'light' | 'dark') => (
      <LucidIconWrapper name="user-round-cog" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
    ),
  },
  {
    label: 'Data Governance',
    link: 'settings/data-governance',
    eventName: EventNames.DATA_GOVERNANCE_CLICKED,
    icon: (theme: 'light' | 'dark') => (
      <LucidIconWrapper name="shield-ellipsis" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
    ),
  },
  {
    label: 'Engage Settings',
    link: 'engage-settings',
    eventName: EventNames.ENGAGE_SETTINGS_CLICKED,
    icon: (theme: 'light' | 'dark') => (
      <LucidIconWrapper name="file-sliders" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
    ),
  },
  {
    label: 'Workspace Settings',
    link: 'workspace-settings',
    eventName: EventNames.WORKSPACE_SETTINGS_CLICKED,
    icon: (theme: 'light' | 'dark') => (
      <LucidIconWrapper name="briefcase-business" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
    ),
  },
];
