import { useIsDemoWorkspace } from '@src/client/hooks';
import { userInfoState } from '@src/client/recoil/atoms';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { cn } from '@src/client/ui-library/utils';
import { useRecoilState } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { ELEVATED_ACCESS_ROLES, MARKETING_ANALYST } from '../site-navbar/constants';
import { SidepanelNavLinkClassNames } from './constants';
import ExpandedNavigationMenu from './ExpandedNavigationMenu';
import FeatureLock from './FeatureLock';
import LinkInfoTooltip from './LinkInfoTooltip';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function EngageMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
  panelRef,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
  panelRef: React.RefObject<HTMLDivElement>;
}) {
  const isFlexicastOpen = currentPathname.includes('flexicast/list');
  const isTemplateGeneratorOpen = currentPathname.includes('engage/create-template');
  const isCampaignOpen = currentPathname.includes('engage/list') && !isTemplateGeneratorOpen;
  const isEngageOpen = isFlexicastOpen || isCampaignOpen || isTemplateGeneratorOpen;
  const isDemoWorkspace = useIsDemoWorkspace();
  const [userInfo] = useRecoilState(userInfoState);
  const { theme } = useTheme();

  if (isDemoWorkspace || ![...ELEVATED_ACCESS_ROLES, MARKETING_ANALYST].includes(userInfo?.roleName ?? '')) {
    return (
      <div
        className={cn(
          `w-full bg-transparent`,
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? '' : 'justify-center',
          'cursor-not-allowed rounded-none',
        )}
      >
        <LinkInfoTooltip content={<p>Please reach out to Percept team for access</p>} side="right" sideOffset={12}>
          <div className="w-full">
            {isNavigationSidepanelOpen ? (
              <div className="flex items-center justify-between w-full">
                <div className="inline-flex items-center space-x-2">
                  <LucidIconWrapper name="megaphone" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
                  <p className="text-sm font-medium text-gray-550 dark:text-gray-150">Engage</p>
                  <FeatureLock isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
                </div>
              </div>
            ) : (
              <div className="relative">
                <LucidIconWrapper name="megaphone" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
                <FeatureLock isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
              </div>
            )}
          </div>
        </LinkInfoTooltip>
      </div>
    );
  }

  return (
    <ExpandedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Engage"
      isActive={isEngageOpen}
      triggerIcon={<LucidIconWrapper name="megaphone" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
      toggleSidepanel={toggleSidepanel}
      panelRef={panelRef}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<LucidIconWrapper name="megaphone" strokeWidth={1.5} />}
          description={isNavigationSidepanelOpen ? 'Campaign creation, management, attribution' : 'Engage'}
        />
      }
    >
      <div className="flex flex-col gap-2 pt-1">
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="engage/list"
          text="Campaigns"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="megaphone" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="megaphone" strokeWidth={1.5} />}
              description="Engage with customers basis their behaviour near real time, configure reminders, build campaigns."
            />
          }
        />
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="flexicast/list"
          text="Flexicast"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="radio" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="radio" strokeWidth={1.5} />}
              description="Create custom campaigns targeting user groups to boost conversions."
            />
          }
        />
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="engage/create-template"
          text="Templates"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="layout-panel-top" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="layout-panel-top" strokeWidth={1.5} />}
              description="Use or design pre-made templates for streamlined campaign launches."
            />
          }
        />
      </div>
    </ExpandedNavigationMenu>
  );
}
