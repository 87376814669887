import { isDateCompareEnabledSelector } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { GranularityEnum, ValueType } from '@src/client/helpers/reports/constants';
import { isLengthyArray } from '@src/client/lib/utils';
import { PageType } from '@src/client/routes/types';
import { tableHoverRowState } from '@src/client/ui-library/table/state';
import * as d3 from 'd3';
import { throttle, uniq, uniqBy } from 'lodash-es';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import DropdownMenu from './LineChartDropDown';
import LineChartTooltip from './LineChartTooltip';
import LinePath from './LinePath';
import { D3LineData, TooltipInfo } from './types';
import { DEFAULT_MARGIN } from './utils';
import XAxis from './XAxis';
import YAxis from './YAxis';

export default function LineChartSvg({
  lineData,
  width,
  height,
  valueType,
  logarithmicScale,
  customXAxis,
  hideYAxis,
  hideXAxis,
  isTinyChart = false,
  pageType = undefined,
}: {
  lineData: D3LineData[];
  width: number;
  height: number;
  valueType?: ValueType;
  logarithmicScale?: boolean;
  hideYAxis: boolean;
  hideXAxis: boolean;
  isTinyChart?: boolean;
  customXAxis?: (
    granularity: GranularityEnum,
    height: number,
    ticks: {
      value: number;
      xOffset: number;
    }[],
  ) => ReactNode;
  pageType?: PageType;
}) {
  const boundsWidth = useMemo(
    () => (isTinyChart ? width : width - DEFAULT_MARGIN.right - DEFAULT_MARGIN.left),
    [width, isTinyChart],
  );
  const boundsHeight = useMemo(
    () => (isTinyChart ? height : height - DEFAULT_MARGIN.top - DEFAULT_MARGIN.bottom),
    [height, isTinyChart],
  );
  const groupedLineData = useMemo(() => d3.groups(lineData, (d) => d.key), [lineData]);
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | undefined>();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isCompareEnabled = useRecoilValue(isDateCompareEnabledSelector);
  const granularity = useRecoilValue(granularityState);
  const hoveredTableRowKey = useRecoilValue(tableHoverRowState);
  const uniqueLineKeys = uniq(lineData.map((l) => l.key));

  const uniqueDateEntries = useMemo(
    () => (isCompareEnabled ? uniqBy(lineData, (d) => d.compareDate!) : uniqBy(lineData, (d) => d.date)),
    [isCompareEnabled, lineData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const activeDataPoint = useMemo(
    () => (tooltipInfo?.i !== undefined ? lineData[tooltipInfo.i] : null),
    [tooltipInfo, lineData],
  );

  const xScale = customXAxis
    ? d3.scaleLinear().domain([0, lineData.length]).range([80, boundsWidth])
    : d3
        .scaleTime()
        .domain(
          isCompareEnabled
            ? [uniqueDateEntries[0].compareDate!, uniqueDateEntries[uniqueDateEntries.length - 1].compareDate!]
            : [uniqueDateEntries[0].date, uniqueDateEntries[uniqueDateEntries.length - 1].date],
        )
        .range([isTinyChart ? 0 : DEFAULT_MARGIN.left, boundsWidth])
        .nice();

  const yScale = logarithmicScale
    ? d3
        .scaleLog()
        .domain(d3.extent(lineData.map((d) => d.value)) as [number, number])
        .range([height - DEFAULT_MARGIN.bottom, DEFAULT_MARGIN.top])
        .nice()
    : d3
        .scaleLinear()
        .domain(d3.extent(lineData.map((d) => d.value)) as [number, number])
        .range([height - DEFAULT_MARGIN.bottom, DEFAULT_MARGIN.top])
        .nice();

  const points = useMemo(
    () => lineData.map((d) => [xScale(isCompareEnabled ? d.compareDate! : d.date), yScale(d.value), d.series]),
    [xScale, yScale, lineData, isCompareEnabled],
  );

  const handleTouchStart = useCallback((e: React.TouchEvent<SVGSVGElement>) => e.preventDefault(), []);

  const handlePointerEnter = useCallback(() => {}, []);

  const handlePointerLeave = useCallback(() => {
    setTooltipInfo(undefined);
    setDropdownOpen(false);
  }, [dropdownOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDotClick = useCallback(() => {
    setTooltipInfo((prev) => {
      if (dropdownOpen) {
        setDropdownOpen(false);
        return undefined;
      }
      setDropdownOpen(true);
      return prev;
    });
  }, [dropdownOpen]);

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      if (dropdownOpen || !isLengthyArray(points)) return;

      const [xm, ym] = d3.pointer(event);

      const minXDistance = d3.min(points, ([x]) => Math.abs(Number(x) - xm));
      if (minXDistance === undefined) return;

      const nearestXPoints = points.filter(([x]) => Math.abs(Number(x) - xm) === minXDistance);

      const closestYPointIndex = d3.leastIndex(nearestXPoints, ([, y]) => Math.abs(Number(y) - ym));
      if (closestYPointIndex === undefined) return;

      const closestPoint = nearestXPoints[closestYPointIndex];
      if (!closestPoint) return;

      const [x, y, k] = closestPoint;
      setTooltipInfo({ x, y, k, i: points.indexOf(closestPoint) });
    },
    [points, dropdownOpen],
  );

  const throttledPointerMove = throttle(handlePointerMove, 200);

  const ticks = useMemo(() => {
    const { ticks: generateTicks } = xScale;
    return generateTicks(Math.min(uniqueDateEntries.length, 8)).map((value) => ({
      value: Number(value),
      xOffset: xScale(value),
    }));
  }, [xScale, uniqueDateEntries.length]);

  const getXAxis = () =>
    customXAxis ? (
      customXAxis(granularity, height, ticks)
    ) : (
      <XAxis
        xScale={xScale as d3.ScaleTime<number, number, never>}
        height={height}
        ticksCount={uniqueDateEntries.length}
      />
    );

  const handleSvgClick = useCallback(() => {
    if (dropdownOpen) {
      setDropdownOpen(false);
      setTooltipInfo(undefined);
    }
  }, [dropdownOpen]);

  return (
    <svg
      className=""
      viewBox={`0 0 ${width} ${height}`}
      onClick={handleSvgClick}
      onPointerEnter={handlePointerEnter}
      onPointerMove={throttledPointerMove}
      onPointerLeave={handlePointerLeave}
      onTouchStart={handleTouchStart}
    >
      {!hideXAxis && getXAxis()}
      {!hideYAxis && <YAxis yScale={yScale} width={width} />}
      {groupedLineData.map((d) => (
        <LinePath
          activeDataPoint={activeDataPoint}
          lineData={d[1]!}
          xScale={xScale}
          yScale={yScale}
          handleDotClick={handleDotClick}
          key={d[0]}
          activeLine={
            (tooltipInfo?.k as string) ??
            (hoveredTableRowKey && uniqueLineKeys.indexOf(hoveredTableRowKey) > -1 ? hoveredTableRowKey : null)
          }
          showDots={
            isTinyChart ? false : granularity !== GranularityEnum.HOUR && granularity !== GranularityEnum.MINUTE
          }
          isTinyChart={isTinyChart}
          pageType={pageType}
        />
      ))}
      {!dropdownOpen && tooltipInfo && (
        <LineChartTooltip
          activeDataPoint={activeDataPoint}
          tooltipInfo={tooltipInfo}
          valueType={valueType}
          chartWidth={boundsWidth}
          chartHeight={boundsHeight}
          notFormatDate={!!customXAxis}
        />
      )}
      {dropdownOpen && activeDataPoint && (
        <DropdownMenu
          activeDataPoint={activeDataPoint}
          x={tooltipInfo?.x as number}
          y={tooltipInfo?.y as number}
          chartWidth={boundsWidth}
          chartHeight={boundsHeight}
          pageType={pageType}
        />
      )}
    </svg>
  );
}
