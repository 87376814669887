import { HomeIcon } from '@client/ui-library/icons/v3';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useIsDemoWorkspace, useIsExperimentEnabled, useIsFeatureFlagEnabled } from '@src/client/hooks';
import { Button } from '@src/client/ui-library/button';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { cn } from '@src/client/ui-library/utils';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LucidIconWrapper from '../lucid-icon-wrapper';
import CreateMenu from './CreateMenu';
import EngageMenu from './EngageMenu';
import ExperimentsMenu from './ExperimentsMenu';
import ReportsMenu from './ReportsMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';
import UserMenu from './UserMenu';

export default function NavigationSidepanel() {
  const { pathname: currentPathname } = useLocation();
  const { theme } = useTheme();

  const [isNavigationSidepanelOpen, setIsNavigationSidepanelOpen] = useState(() =>
    JSON.parse(localStorage.getItem('percept_ns_state') || 'true'),
  );
  const isExperimentEnabled = useIsExperimentEnabled();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();
  const canShowExperiments = isExperimentEnabled || isFeatureFlagEnabled || isWorkspaceCreatedViaOnboardingFlow;

  const isDemoWorkspace = useIsDemoWorkspace();

  useEffect(() => {
    localStorage.setItem('percept_ns_state', JSON.stringify(isNavigationSidepanelOpen));
  }, [isNavigationSidepanelOpen]);

  const panelRef = useRef<HTMLDivElement | null>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    const panel = panelRef.current;
    if (!panel) return undefined;

    if (!isMounted.current) {
      const savedScroll = sessionStorage.getItem('sidepanel-scroll') || '0';
      panel.scrollTop = parseInt(savedScroll, 10);
      isMounted.current = true;
    }

    const handleScroll = () => {
      sessionStorage.setItem('sidepanel-scroll', panel.scrollTop.toString());
    };

    panel.addEventListener('scroll', handleScroll);

    return () => {
      panel.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigationSidepanelToggle = (open: boolean) => {
    setIsNavigationSidepanelOpen(open);
  };
  return (
    <div
      className={`flex-shrink-0 w-64 border-r-2 border-border transition-width duration-300 z-[20]
        ${isNavigationSidepanelOpen ? 'w-[260px]' : 'w-[60px]'}
        `}
    >
      <div
        className={cn(
          `w-full overflow-y-scroll pt-2 ${isNavigationSidepanelOpen ? 'px-4' : 'px-2'} flex flex-col no-scrollbar`,
          `sticky z-[11] bg-background left-0 top-[60px] h-layout `,
        )}
      >
        <div
          ref={panelRef}
          onPointerEnter={() => setIsNavigationSidepanelOpen(true)}
          className="flex-1 overflow-y-scroll pb-12 no-scrollbar"
        >
          <div className="border-b border-border py-2 pb-3 sticky top-0 z-20 bg-background">
            <CreateMenu
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              currentPathname={currentPathname}
              toggleSidepanel={setIsNavigationSidepanelOpen}
            />
          </div>
          <div className={cn('border-b border-border py-2 space-y-2')}>
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="home"
              text="Home"
              panelRef={panelRef}
              icon={<LucidIconWrapper name="house" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<HomeIcon />}
                  description="Product metrics and recently viewed reports at a glance"
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="events"
              text="Live Events"
              panelRef={panelRef}
              icon={
                <LucidIconWrapper
                  name="mouse-pointer-click"
                  strokeWidth={1.5}
                  stroke={theme === 'dark' ? 'white' : ''}
                />
              }
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<LucidIconWrapper name="mouse-pointer-click" strokeWidth={1.5} />}
                  description={isNavigationSidepanelOpen ? 'Real time events dashboard' : 'Events'}
                />
              }
            />
          </div>
          <div className={cn('border-b border-border py-2 space-y-2')}>
            <ReportsMenu
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              currentPathname={currentPathname}
              toggleSidepanel={setIsNavigationSidepanelOpen}
              panelRef={panelRef}
            />
          </div>
          {!isDemoWorkspace ? (
            <div className={cn('border-b border-border py-2 space-y-2')}>
              <UserMenu
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                currentPathname={currentPathname}
                toggleSidepanel={setIsNavigationSidepanelOpen}
                panelRef={panelRef}
              />
            </div>
          ) : null}
          <div className={cn('border-b border-border py-2 space-y-2')}>
            <EngageMenu
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              currentPathname={currentPathname}
              toggleSidepanel={setIsNavigationSidepanelOpen}
              panelRef={panelRef}
            />
          </div>
          <div className={cn(' py-2 space-y-2')}>
            {canShowExperiments ? (
              <ExperimentsMenu
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                currentPathname={currentPathname}
                toggleSidepanel={setIsNavigationSidepanelOpen}
                panelRef={panelRef}
              />
            ) : null}
          </div>
        </div>
        <div className={isNavigationSidepanelOpen ? 'text-right mb-2' : 'text-center mb-2'}>
          <Button
            variant="icon"
            onClick={() => handleNavigationSidepanelToggle(!isNavigationSidepanelOpen)}
            className="bg-transparent p-2"
          >
            <LucidIconWrapper
              name={isNavigationSidepanelOpen ? 'arrow-left-to-line' : 'arrow-right-to-line'}
              stroke={theme === 'dark' ? 'white' : ''}
            />
          </Button>
        </div>
      </div>
    </div>
  );
}
