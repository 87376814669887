import { useTheme } from '@src/client/ui-library/theme-provider';
import { useCallback, useState } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import GlobalSearchModal from './SearchModal';

export default function NavbarSearch() {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const openModal = useCallback(() => setShowSearchModal(true), []);
  const closeModal = useCallback(() => setShowSearchModal(false), []);
  const { theme } = useTheme();
  const strokeColor = theme === 'light' ? '#667085' : '#999999';

  return (
    <>
      <div
        className="py-2 px-5 flex items-center bg-transparent w-full space-x-2 rounded-md"
        onClick={openModal}
        role="button"
        tabIndex={0}
        onKeyDown={openModal}
        data-testid="global-search-button"
      >
        <LucidIconWrapper name="search" stroke="white" />
        <p className="text-md font-medium">Search</p>
      </div>
      {showSearchModal ? <GlobalSearchModal open={showSearchModal} onClose={closeModal} /> : null}
    </>
  );
}
