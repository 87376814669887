import SidepanelNavLink from './SidepanelNavLink';

export default function CreateMenuExperiments() {
  return (
    <>
      <div className="w-full pl-2 pr-4 py-2 pt-5">
        <SidepanelNavLink
          isNavigationSidepanelOpen
          path="experiment/create"
          text="Experiment"
          icon={<img alt="experiment" height={32} width={32} src="/images/v2/experiment.png" />}
        />
      </div>
      <div className="w-full pl-2 pr-4 py-2">
        <SidepanelNavLink
          isNavigationSidepanelOpen
          path="feature-flag/create"
          text="Feature Flags"
          icon={<img alt="feature-flag" height={32} width={32} src="/images/v2/feature-flag.png" />}
        />
      </div>
    </>
  );
}
