import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useIsDemoWorkspace, useIsHeatMapEnabled } from '@src/client/hooks';
import { integratedSdksState } from '@src/client/modules/sdk-integration/atoms';
import { SdkType } from '@src/client/modules/sdk-integration/types';
import { userInfoState } from '@src/client/recoil/atoms';
import { FlowIcon, FunnelIcon, RetentionIcon } from '@src/client/ui-library/icons/v3';
import { useTheme } from '@src/client/ui-library/theme-provider';
import { cn } from '@src/client/ui-library/utils';
import { useRecoilState, useRecoilValue } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import ExpandedNavigationMenu from './ExpandedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function ReportsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
  panelRef,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
  panelRef: React.RefObject<HTMLDivElement>;
}) {
  const isAllReportsPageOpen = currentPathname.includes('all-reports');
  const isdashboardsPageOpen = currentPathname.includes('dashboards-list');
  const isheatmapsPageOpen = currentPathname.includes('heatmaps/dashboard');
  const isReportsPageOpen = isAllReportsPageOpen || isdashboardsPageOpen || isheatmapsPageOpen;
  const isHeatMapEnabled = useIsHeatMapEnabled();
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();
  const [userInfo] = useRecoilState(userInfoState);
  const integratedSdks = useRecoilValue(integratedSdksState);
  const isWebSdkIntegrated = integratedSdks?.some((s) => s.sdkType.toLowerCase() === SdkType.WEB.toLowerCase());
  const canShowHeatmaps =
    isHeatMapEnabled ||
    (isWorkspaceCreatedViaOnboardingFlow && isWebSdkIntegrated) ||
    (isWebSdkIntegrated && userInfo?.activeTenantId !== 'supermoney');

  const isDemoWorkspace = useIsDemoWorkspace();
  const { theme } = useTheme();

  return (
    <ExpandedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Analytics"
      isActive={isReportsPageOpen}
      toggleSidepanel={toggleSidepanel}
      triggerIcon={
        <LucidIconWrapper name="file-chart-column" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
      }
      isExpanded
      panelRef={panelRef}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<LucidIconWrapper name="file-chart-column" strokeWidth={1.5} />}
          description="View available reports "
        />
      }
    >
      <div className="flex flex-col gap-2 pt-1">
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="all-reports"
          text="All Reports"
          panelRef={panelRef}
          icon={
            <LucidIconWrapper name="file-chart-column" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
          }
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="file-chart-column" strokeWidth={1.5} />}
              description="View all reports "
            />
          }
        />

        {isNavigationSidepanelOpen ? (
          <div className={cn('pl-8 flex flex-col gap-2')}>
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="all-reports/insights-list"
              text="Insights"
              panelRef={panelRef}
              icon={
                <LucidIconWrapper name="chart-bar-big" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />
              }
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<LucidIconWrapper name="chart-bar-big" strokeWidth={1.5} />}
                  description="Deep dive into behavior trends and key event analytics over time."
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="all-reports/funnels-list"
              text="Funnels"
              panelRef={panelRef}
              icon={<FunnelIcon stroke={theme === 'dark' ? 'white' : ''} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<FunnelIcon />}
                  description="Track, visualize and engage in the user journey, including points of drop-off."
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="all-reports/flows-list"
              text="Flows"
              panelRef={panelRef}
              icon={<FlowIcon stroke={theme === 'dark' ? 'white' : ''} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<FlowIcon />}
                  description="Analyze user pathways from entry point to final action."
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="all-reports/retentions-list"
              text="Retention"
              panelRef={panelRef}
              icon={<RetentionIcon stroke={theme === 'dark' ? 'white' : ''} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<RetentionIcon />}
                  description="Measure user retention and repeats by tracking repeat visits and engagement frequency."
                />
              }
            />
          </div>
        ) : null}
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="dashboards-list"
          text="Dashboards"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="layout-dashboard" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="layout-dashboard" strokeWidth={1.5} />}
              description="Visualize data, standardize reporting and make decisions with dashboards"
            />
          }
        />
        {canShowHeatmaps && !isDemoWorkspace ? (
          <SidepanelNavLink
            isNavigationSidepanelOpen={isNavigationSidepanelOpen}
            path="heatmaps/dashboard"
            text="Heatmaps"
            panelRef={panelRef}
            icon={<LucidIconWrapper name="flame" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
            tooltipContent={
              <SidepanelNavlinkTooltip
                icon={<LucidIconWrapper name="flame" strokeWidth={1.5} />}
                description={
                  isNavigationSidepanelOpen ? 'Check click frequencies, dead clicks and scroll depth' : 'Heatmaps'
                }
              />
            }
          />
        ) : null}
      </div>
    </ExpandedNavigationMenu>
  );
}
