import { ELEVATED_ACCESS_ROLES } from '@src/client/components/site-navbar/constants';
import { userInfoState } from '@src/client/recoil/atoms';
import { useRecoilValue } from 'recoil';

import { DeleteIcon } from '../icons/DashboardIcons';
import { Button } from '.';

interface ItemDeleteButtonProps {
  createdBy: string;
  enableForCurrentUser?: boolean;
  onClick: (item: unknown) => void;
}

export default function ItemDeleteButton({ createdBy, enableForCurrentUser = false, onClick }: ItemDeleteButtonProps) {
  const userInfo = useRecoilValue(userInfoState);
  const ELEVATED_ACCESS_ROLE_SET = new Set(ELEVATED_ACCESS_ROLES);

  const showDelete = Boolean(
    userInfo &&
      ((enableForCurrentUser && createdBy === userInfo.email) ||
        (userInfo.roleName && ELEVATED_ACCESS_ROLE_SET.has(userInfo.roleName))),
  );

  return showDelete ? (
    <Button variant="icon" onClick={onClick}>
      <DeleteIcon />
    </Button>
  ) : null;
}
