import { useIsDemoWorkspace } from '@src/client/hooks';
import { userInfoState } from '@src/client/recoil/atoms';
import { cn } from '@src/client/ui-library/utils';
import { useRecoilState } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { ELEVATED_ACCESS_ROLES, MARKETING_ANALYST } from '../site-navbar/constants';
import { SidepanelNavLinkClassNames } from './constants';
import FeatureLock from './FeatureLock';
import LinkInfoTooltip from './LinkInfoTooltip';
import SidepanelNavLink from './SidepanelNavLink';

export default function CreateMenuEngage({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  const isDemoWorkspace = useIsDemoWorkspace();
  const [userInfo] = useRecoilState(userInfoState);

  if (isDemoWorkspace || ![...ELEVATED_ACCESS_ROLES, MARKETING_ANALYST].includes(userInfo?.roleName ?? '')) {
    return (
      <div
        className={cn(
          `w-full bg-transparent`,
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? '' : ' justify-center',
          'cursor-not-allowed rounded-none',
        )}
      >
        <LinkInfoTooltip content={<p>Please reach out to Percept team for access</p>} side="right" sideOffset={12}>
          <div className="w-full">
            <div className="flex items-center justify-between w-full">
              <div className="inline-flex items-center space-x-2">
                <LucidIconWrapper name="megaphone" strokeWidth={1.5} />
                <p className="text-sm font-medium text-gray-400">Engage</p>
                <FeatureLock isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
              </div>
            </div>
          </div>
        </LinkInfoTooltip>
      </div>
    );
  }

  return (
    <>
      <div className="w-full pl-2 pr-4 py-2 pt-5">
        <SidepanelNavLink
          isNavigationSidepanelOpen
          path="engage/create"
          text="Campaign"
          icon={<img alt="campaign" height={32} width={32} src="/images/v2/campaign.png" />}
        />
      </div>
      <div className="w-full pl-2 pr-4 py-2">
        <SidepanelNavLink
          isNavigationSidepanelOpen
          path="flexicast/create"
          text="Flexicast"
          icon={<img alt="flexicast" height={32} width={32} src="/images/v2/flexicast.png" />}
        />
      </div>
      <div className="w-full pl-2 pr-4 py-2">
        <SidepanelNavLink
          isNavigationSidepanelOpen
          path="engage/create-template"
          text="Template"
          icon={<img alt="template" height={32} width={32} src="/images/v2/template.png" />}
        />
      </div>
    </>
  );
}
