import LucidIconWrapper from '@src/client/components/lucid-icon-wrapper';
import React from 'react';

import { cn } from '../utils';

interface CreateNewButtonProps {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
  className?: string;
}

function CreateNewButton({ label, onClick, icon, className }: CreateNewButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'flex items-center gap-2 px-4 py-2 rounded bg-blue-600 text-white hover:bg-blue-700 transition',
        className,
      )}
    >
      {icon && <span>{icon}</span>}
      <span>{label}</span>
      <div className="pl-3">
        <LucidIconWrapper name="plus" strokeWidth={1.5} stroke="white" />
      </div>
    </button>
  );
}

export default CreateNewButton;
