import { useTheme } from '@src/client/ui-library/theme-provider';

import LucidIconWrapper from '../lucid-icon-wrapper';
import ExpandedNavigationMenu from './ExpandedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function UserMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
  panelRef,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
  panelRef: React.RefObject<HTMLDivElement>;
}) {
  const isProfilePageOpen = currentPathname.includes('user-profiles/list');
  const isCohortPageOpen = currentPathname.includes('cohort/list');
  const isUserPageOpen = isProfilePageOpen || isCohortPageOpen;
  const { theme } = useTheme();

  return (
    <ExpandedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Users"
      isActive={isUserPageOpen}
      triggerIcon={<LucidIconWrapper name="users" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
      toggleSidepanel={toggleSidepanel}
      isExpanded
      panelRef={panelRef}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<LucidIconWrapper name="users" strokeWidth={1.5} />}
          description={isNavigationSidepanelOpen ? 'Create customer segmentations, view user level journeys' : 'Users'}
        />
      }
    >
      <div className="flex flex-col gap-2 pt-1">
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="cohort/list"
          text="Cohorts"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="users" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="users" strokeWidth={1.5} />}
              description="Group users by shared characteristics for personalized targeting."
            />
          }
        />
        <SidepanelNavLink
          isNavigationSidepanelOpen={isNavigationSidepanelOpen}
          path="user-profiles/list"
          text="Profile"
          panelRef={panelRef}
          icon={<LucidIconWrapper name="contact-round" strokeWidth={1.5} stroke={theme === 'dark' ? 'white' : ''} />}
          tooltipContent={
            <SidepanelNavlinkTooltip
              icon={<LucidIconWrapper name="contact-round" strokeWidth={1.5} />}
              description="Access detailed, organized user profiles for data-driven decisions."
            />
          }
        />
      </div>
    </ExpandedNavigationMenu>
  );
}
